// React libs
import React, { FC, useCallback, useMemo } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Components
import Button from '../../../../../Core/Components/UiKit/Button/Button';
import CancelButton from '../../../../../Core/Components/UiKit/Form/CancelButton/CancelButton';
import Checkbox from '../../../../../Core/Components/UiKit/Form/Checkbox/Checkbox';
import DatePicker from '../../../../../Core/Components/UiKit/Form/DatePicker/DatePicker';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import TextField from '../../../../../Core/Components/UiKit/Form/TextField/TextField';
// Type
import * as CoreTypes from '../../../../../Core/Data/Models/Core.type';
import * as Types from './ShareSelectionForm.type';
// Utils
import { momentToISOString, copyToClipboard as copyToClipboardUtils } from '../../../../../Core/Utils/Misc';
import { getFormPropTypes } from '../../../../../Core/Utils/FormUtils';
// Common
import CoreCommon from '../../../../../Core/Resources/Common';
import AppCommon from '../../../../../App/Resources/Common';

const ShareSelectionForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <ShareSelectionFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

ShareSelectionForm.propTypes = getFormPropTypes();

export default ShareSelectionForm;

const ShareSelectionFormikForm = ({
  isSubmitting,
  miscData,
  miscFunctions,
  values,
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['map', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const sharedId = miscData?.shareId
  const link = useMemo(() =>
    sharedId !== undefined
      ? `${window.location.origin}/${AppCommon.Routes.routeSharedMap}?embed=${btoa(sharedId)}&title=${values.display}&legend=${values.legend}`
      : ''
    , [sharedId, values.display, values.legend])
  const frame = link !== '' ? `<iframe scrolling="no" width="560" height="315" title=${values.display} frameborder="0" src="${link}" allowfullscreen></iframe>` : ''

  // Actions
  const copyToClipboard = useCallback((text: string) => {
    copyToClipboardUtils(text).then(
      () => {
        enqueueSnackbar(t('map:sidebar.filters.favoriteFilter.copied'), {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'success',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
      },
      (e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
          }
        );
      }
    )
  }, [enqueueSnackbar, t])

  return (
    <Form className='h-full'>
      <div className='h-9.5/10 min-h-60 overflow-auto'>
        <div className='grid grid-cols-10 gap-2'>
          <div className='col-span-7'>
            <Field
              name='legend'
              component={TextField}
              label={t('map:sidebar.filters.favoriteFilter.legend')}
            />
          </div>
          <div className='col-span-3 mt-5'>
            <Field
              name='display'
              component={Checkbox}
              label={t('map:sidebar.filters.favoriteFilter.display')}
            />
          </div>
        </div>
        <Field
          name='expirationDate'
          disabled={sharedId !== undefined}
          format='DD/MM/YYYY'
          component={DatePicker}
          transform={(date: any) => date != null ? momentToISOString(date) : ''}
          label={t('map:sidebar.filters.favoriteFilter.expirationDate')}
        />
        {sharedId !== undefined && <div className='mt-3'>
          <TextField
            form={{}}
            field={{ name: 'link', value: link }}
            InputProps={{
              className: 'text-selection bg-main-light p-1',
              endAdornment: <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.copyLink') ?? ''}>
                <InputAdornment position='end'>
                  <FaIcon name='link' className='cursor-pointer text-xl text-active' onClick={() => copyToClipboard(link)} />
                </InputAdornment>
              </Tooltip>
            }}
          />
          <TextField
            form={{}}
            field={{ name: 'frame', value: frame }}
            multiline
            InputProps={{
              className: 'text-selection bg-main-light p-1 mt-2',
            }}
          />
          <Button
            color='secondary'
            className='w-full mt-2'
            startIcon={<FaIcon name='code' className='text-lg' />}
            onClick={() => copyToClipboard(frame)}
          >
            {t('map:sidebar.filters.favoriteFilter.integrate')}
          </Button>
        </div>}
      </div>
      <div className='mt-1 w-full text-center'>
        <CancelButton className='mr-2' onCancel={miscFunctions.onCancel} />
        {sharedId === undefined
          ? <Button
            className='hover:bg-active-hover'
            color='secondary'
            type='submit'
            size='small'
            disabled={isSubmitting}
            startIcon={
              isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : <FaIcon name='share' className='text-lg' />
            }
          >
            {t('map:sidebar.filters.favoriteFilter.share')}
          </Button>
          : <Button
            className='hover:bg-active-hover'
            size='small'
            startIcon={<FaIcon name='stop-circle' type='fas' className='text-lg' />}
            onClick={miscFunctions.onStopShare}
          >
            {t('map:sidebar.filters.favoriteFilter.stopShare')}
          </Button>}
      </div>
    </Form>
  );
};

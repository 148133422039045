// React libs
import React, { FC, useState, useCallback, useContext, useMemo } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, FormikProps, Formik, Field } from 'formik'
import { keyBy } from 'lodash';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../../Core/Components/UiKit/Button/Button'
import CancelButton from '../../../../../../../Core/Components/UiKit/Form/CancelButton/CancelButton';
import DeleteSelectionConfirmModal from '../../../../Modales/DeleteSelection/DeleteSelectionConfirmModal'
import FaIcon from '../../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon'
import LocalLoader from '../../../../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import SaveButton from '../../../../../../../Core/Components/UiKit/Form/SaveButton/SaveButton';
import ShareSelectionForm from '../../../../Form/ShareSelection/ShareSelectionForm';
import TextField from '../../../../../../../Core/Components/UiKit/Form/TextField/TextField'
// Contexts
import ActiveLayerContext, { IActiveLayerContext } from '../../../../../Data/Contexts/ActiveLayerContext';
import MapContext, { IMapContext } from '../../../../../Data/Contexts/MapContext'
import LinkTypeContext, { ILinkTypeContext } from '../../../../../Data/Contexts/LinkTypesContext';
import MapConfigContext, { IMapConfigContext } from '../../../../../Data/Contexts/MapConfigContext';
import MapFiltersContext, { IMapFiltersContext } from '../../../../../Data/Contexts/MapFiltersContext';
import PhaseTypeContext, { IPhaseTypeContext } from '../../../../../Data/Contexts/PhaseTypeContext';
import UserContext, { IUserContext } from '../../../../../../../Core/Data/Contexts/UserContext';
// Services
import LocalStorage from '../../../../../../../Core/Data/Services/Storage/LocalStorage';
import MapService from '../../../../../Data/Services/MapService'
// Types
import * as CoreTypes from '../../../../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../../../../../Data/Models/Map.type';
import * as Types from './FavoriteFilters.type'
// Utils
import useConfirmModal from '../../../../../../../Core/Utils/useConfirmModal'
import { formatMapFilters, parseMapFilters } from '../../../../../Utils/Map'
import { momentToISOString } from '../../../../../../../Core/Utils/Misc';
// Common
import CoreCommon from '../../../../../../../Core/Resources/Common';
// Hooks
import useSelections from '../../../../../Data/Hooks/Selections'
import useMapShares from '../../../../../Data/Hooks/MapShares'

interface IFavoriteFiltersForm {
  enableDefaultView: () => void
  selection?: MapTypes.ISelection
}

const FavoriteFiltersForm: FC<IFavoriteFiltersForm> = ({ enableDefaultView, selection }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['map', 'common'])
  const initialValues = {
    name: selection?.label ?? ''
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('map:sidebar.filters.favoriteFilter.errors.requiredName')),
  })

  // Contexts
  const { activeLayer }: IActiveLayerContext = useContext(ActiveLayerContext)
  const { mapFilters }: IMapFiltersContext = useContext(MapFiltersContext)
  const { mapConfig }: IMapConfigContext = useContext(MapConfigContext)
  const { user }: IUserContext = useContext(UserContext)
  const { map }: IMapContext = useContext(MapContext)

  // Actions
  const saveSelection = useCallback(async name => {
    const { lat, lng } = map?.getCenter()
    const zoom = map?.getZoom()
    const props = {
      data: {
        mapView: {
          center: [lat, lng],
          zoom,
        },
        selection: formatMapFilters(mapFilters),
      },
      fkaccount: user?.profile.person.account.id,
      fklayerMap: activeLayer?.id ?? mapConfig?.layerMap.id,
      label: name
    }
    const promise = selection !== undefined ? MapService.updateSelection(selection.id, props) : MapService.saveSelection(props)
    await promise.catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:errors.defaultMessage'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        }
      );
    })
  }, [activeLayer, enqueueSnackbar, map, mapConfig, mapFilters, selection, t, user])

  // handlers
  const onSubmit = useCallback(({ name }, actions) => {
    saveSelection(name).finally(() => {
      actions.setSubmitting(false)
      enableDefaultView()
    })
  }, [saveSelection, enableDefaultView])

  return <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {(formikProps: FormikProps<any>) => (
      <Form>
        <Field
          name='name'
          component={TextField}
          label={t('map:sidebar.filters.favoriteFilter.name')}
        />
        <div className='absolute bottom-0 p-2 w-full text-center'>
          <CancelButton className='mr-2' onCancel={() => enableDefaultView()} />
          <SaveButton isSubmitting={formikProps.isSubmitting} />
        </div>
      </Form>
    )}
  </Formik>
}

interface IFavoriteFiltersVue {
  enableEditView: (selection?: MapTypes.ISelection) => void
  enableShareView: (data: { selection: MapTypes.ISelection, mapShare: MapTypes.ISelection | undefined }) => void
}

const FavoriteFiltersVue: FC<IFavoriteFiltersVue> = ({ enableEditView, enableShareView }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['map', 'common'])
  const selections = useSelections()
  const deleteSelectionModal = useConfirmModal()
  const mapShares = useMapShares()
  const isLoading = selections.isLoading || mapShares.isLoading
  const mapSharesBySelection: any = useMemo(() => keyBy(mapShares.data, mapShare => JSON.parse(mapShare.data).fkSelection), [mapShares.data])

  // Contexts
  const { mapFilters, updateMapFilters }: IMapFiltersContext = useContext(MapFiltersContext)
  const { setActiveLayer }: IActiveLayerContext = useContext(ActiveLayerContext)
  const { setCurrentCenter, setCurrentZoom }: IMapContext = useContext(MapContext)

  // Handlers
  const onEdit = useCallback(selection => {
    enableEditView(selection)
  }, [enableEditView])
  const onDelete = useCallback(selection => {
    deleteSelectionModal.openModal().then(() => {
      MapService.deleteSelection(selection.id).catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
      }).finally(() => selections.refresh())
    }, () => { })
  }, [deleteSelectionModal, enqueueSnackbar, selections, t])
  const onApply = useCallback(selection => {
    if (mapFilters.activeSelection === selection.id) {
      const persistedMapFilters = LocalStorage.get(LocalStorage.keys.mapFilters)
      const persistedMapCoordinates = LocalStorage.get(LocalStorage.keys.mapCoordinates)
      const persistedMapLayer = LocalStorage.get(LocalStorage.keys.mapLayer)

      updateMapFilters({
        ...persistedMapFilters,
        needMapRefresh: true,
      })
      setActiveLayer(persistedMapLayer ?? undefined)
      setCurrentCenter(persistedMapCoordinates?.center)
      setCurrentZoom(persistedMapCoordinates?.zoom)
    } else {
      const data = JSON.parse(selection.data)
      updateMapFilters({
        ...mapFilters,
        needMapRefresh: true,
        ...parseMapFilters(data.selection),
        temporal: true,
        activeSelection: selection.id
      })
      setCurrentCenter(data.mapView.center)
      setCurrentZoom(data.mapView.zoom)

      setActiveLayer(selection.layerMap)
    }
  }, [mapFilters, setActiveLayer, setCurrentCenter, setCurrentZoom, updateMapFilters])
  const onShare = useCallback(selection => {
    enableShareView({
      selection,
      mapShare: mapSharesBySelection[selection.id]
    })
  }, [enableShareView, mapSharesBySelection])

  if (isLoading) {
    return <div className='flex h-full items-center justify-center w-full'>
      <LocalLoader message={t('map:sidebar.filters.favoriteFilter.loading.selections')} />
    </div>
  }

  return <div>
    <Button
      color='secondary'
      size='small'
      className='w-full'
      onClick={() => enableEditView()}
      startIcon={<FaIcon name='plus-circle' className='text-lg' />}
    >
      {t('map:sidebar.filters.favoriteFilter.saveCurrentMap')}
    </Button>
    <List dense className='p-0'>
      {selections.data?.map(selection =>
        <ListItem key={selection.id} classes={{
          root: 'bg-main-light text-selection'
        }}>
          <ListItemText primary={
            <Tooltip title={selection.label}>
              <p className='max-w-48 truncate'>{selection.label}</p>
            </Tooltip>
          } />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" size='small' className='mr-1' onClick={() => onEdit(selection)}>
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.edit') ?? ''}>
                <div>
                  <FaIcon name='pencil' />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton edge="end" aria-label="delete" size='small' className='mr-1' onClick={() => onDelete(selection)}>
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.delete') ?? ''}>
                <div>
                  <FaIcon name='trash' />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton edge="end" aria-label="apply" size='small' className={`mr-1 hover:text-active-hover ${mapFilters.activeSelection === selection.id ? 'text-active' : ''}`} onClick={() => onApply(selection)}>
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.apply') ?? ''}>
                <div>
                  <FaIcon name='eye' />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="share"
              size='small'
              onClick={() => onShare(selection)}
              className={mapSharesBySelection[selection.id] !== undefined ? 'text-active' : ''}
            >
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.share') ?? ''}>
                <div>
                  <FaIcon name='share' />
                </div>
              </Tooltip>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>)}
    </List>

    <DeleteSelectionConfirmModal
      handleClose={deleteSelectionModal.onClose}
      isOpened={deleteSelectionModal.isOpened}
    />
  </div>
}

interface IFavoriteFiltersShareForm {
  enableDefaultView: () => void
  selection: MapTypes.ISelection | undefined
  mapShare: MapTypes.IMapShare | undefined
}

const FavoriteFiltersShareForm: FC<IFavoriteFiltersShareForm> = ({ enableDefaultView, selection, mapShare }) => {
  // State
  const [shareId, setShareId] = useState<string | undefined>(mapShare?.id)

  // Variables
  const { t } = useTranslation('map')
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = useMemo(() => ({
    legend: selection?.label ?? '',
    display: true,
    expirationDate: momentToISOString(moment().add(1, 'years'))
  }), [selection])
  const validationSchema = Yup.object().shape({
    legend: Yup.string(),
    display: Yup.boolean(),
    expirationDate: Yup.string().required(t('map:sidebar.filters.favoriteFilter.errors.requiredExpirationDate'))
  })
  // Contexts
  const { linkTypes }: ILinkTypeContext = useContext(LinkTypeContext)
  const { phaseTypes }: IPhaseTypeContext = useContext(PhaseTypeContext)

  // Handlers
  const onSubmit = useCallback((values, actions) => {
    const data = JSON.parse(selection?.data ?? '')
    data.fkSelection = selection?.id
    MapService.shareMap({
      data: JSON.stringify(data),
      expirationDate: values.expirationDate,
      fkaccount: selection?.fkaccount,
      fklayerMap: selection?.layerMap.id,
      type: "PUBLIC",
      options: JSON.stringify({
        title: values.display,
        legend: values.legend,
        linkTypes,
        phases: phaseTypes,
      })
    }).then(
      ({ data }) => {
        setShareId(data.id)
      },
      (e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
      }).finally(() => {
        actions.setSubmitting(false)
      })
  }, [enqueueSnackbar, linkTypes, phaseTypes, selection, t])

  // Actions
  const onStopShare = useCallback(() => {
    if (shareId !== undefined) {
      MapService.stopMapShare(shareId).then(
        () => {
          enableDefaultView()
        },
        (e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
        }
      )
    }
  }, [enableDefaultView, enqueueSnackbar, shareId, t])

  return <ShareSelectionForm
    defaultValues={defaultValues}
    onFormSubmit={onSubmit}
    validationSchema={validationSchema}
    miscFunctions={{
      onCancel: enableDefaultView,
      onStopShare,
    }}
    miscData={{
      shareId
    }}
  />
}

const FavoriteFilters: FC<Types.IProps> = () => {
  // State
  const [activeView, setActiveView] = useState<'default' | 'edit' | 'share'>('default')
  const [selectedSelection, setSelectedSelection] = useState<MapTypes.ISelection | undefined>()
  const [mapShare, setMapShare] = useState<MapTypes.IMapShare | undefined>()
  // handlers
  const enableEditView = useCallback(selection => {
    setSelectedSelection(selection)
    setActiveView('edit')
  }, [])
  const enableShareView = useCallback(({ selection, mapShare }) => {
    setSelectedSelection(selection)
    setMapShare(mapShare)
    setActiveView('share')
  }, [])
  const enableDefaultView = useCallback(() => {
    setActiveView('default')
  }, [])

  const views = {
    'edit': <FavoriteFiltersForm enableDefaultView={enableDefaultView} selection={selectedSelection} />,
    'share': <FavoriteFiltersShareForm enableDefaultView={enableDefaultView} selection={selectedSelection} mapShare={mapShare} />,
    'default': <FavoriteFiltersVue enableEditView={enableEditView} enableShareView={enableShareView} />
  }

  return <div data-testid='map-sidebar-favorite-filters' className='h-full'>
    {views[activeView]}
  </div>
}

export default FavoriteFilters

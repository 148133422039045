// React libs
import L from 'leaflet'
import ReactDOMServer from 'react-dom/server';
// Type
import * as MapTypes from '../Data/Models/Map.type';
// Utils
import { getMarkerImage } from './Map'

interface IData {
  color: string;
  name: string;
  value: number;
}
export interface IDonutProps {
  colors?: string[];
  data: IData[];
  el?: any;
  position: 'left' | 'center' | 'right';
  size: number;
  text: {
    text: string;
    align: string;
  };
  weight: number;
}

export const createClusterDonut = ({
  colors,
  data,
  el,
  position,
  size,
  text,
  weight,
}: IDonutProps) => {
  const div = document.createElement('div');
  const r = size / 2;
  const setAttribute = (el: any, o: any) => {
    for (const j in o) {
      el.setAttribute(j, o[j]);
    }
  };
  const sum = data.reduce((sum: number, d: IData) => sum + d.value, 0);
  const NS = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(NS, 'svg');
  const arcRadius = r - weight / 2;
  let startAngle = -Math.PI / 2;

  div.className = 'donut';
  div.style.width = div.style.height = size + 'px';
  if (position === 'center') {
    div.style.position = 'relative';
    div.style.top = -size / 2 + 'px';
    div.style.left = -size / 2 + 'px';
  }

  if (text) {
    let span = document.createElement('span');
    span.className = 'text';
    span.innerHTML = text.text;

    span.style.position = 'relative';
    span.style.zIndex = '1600';

    if (text.align === 'center') {
      span.style.display = 'inline-block';
      span.style.lineHeight = '100%';
      span.style.verticalAlign = 'middle';
      div.style.lineHeight = div.style.height;
      div.style.textAlign = 'center';
    }

    div.appendChild(span);
  }

  svg.setAttribute('height', size + 'px');
  svg.setAttribute('width', size + 'px');

  div.appendChild(svg);

  data.forEach((d: IData, index: number) => {
    let value = d.value / sum;
    value = value === 1 ? 0.9999 : value;
    const arc = document.createElementNS(NS, 'path');

    var segmentAngle = value * Math.PI * 2,
      endAngle = segmentAngle + startAngle,
      largeArc = (endAngle - startAngle) % (Math.PI * 2) > Math.PI ? 1 : 0,
      startX = r + Math.cos(startAngle) * arcRadius,
      startY = r + Math.sin(startAngle) * arcRadius,
      endX = r + Math.cos(endAngle) * arcRadius,
      endY = r + Math.sin(endAngle) * arcRadius;

    startAngle = endAngle;

    setAttribute(arc, {
      d: [
        'M',
        startX,
        startY,
        'A',
        arcRadius,
        arcRadius,
        0,
        largeArc,
        1,
        endX,
        endY,
      ].join(' '),
      stroke: d.color || (colors ? colors[index % colors.length] : '#ddd'),
      'stroke-width': weight,
      fill: 'none',
      'data-name': d.name,
      class: 'donut-arc',
    });

    svg.appendChild(arc);
  });

  // end
  if (el) {
    el.appendChild(div);
  }

  return div;
}

export const createClusters = () => {
  const clusterOptions = {
    showCoverageOnHover: false,
    maxClusterRadius: 55,
    removeOutsideVisibleBounds: true,
    chunkedLoading: true,
    iconCreateFunction: (cluster: any) => {
      cluster.colors = {};
      cluster.ContainedPOIs = {};
      cluster.getAllChildMarkers().forEach((marker: any) => {
        const color = marker.options.icon.options.color;
        const PoiType = marker.PoiType;
        if (!(color in cluster.colors)) cluster.colors[color] = [];
        if (!(PoiType in cluster.ContainedPOIs))
          cluster.ContainedPOIs[PoiType] = [];
        cluster.ContainedPOIs[PoiType].push(marker.Kid);
        cluster.colors[color].push(marker.Kid);
        cluster.cluster = true;
      });
      const data = [];
      for (let color in cluster.colors) {
        data.push({
          value: cluster.colors[color].length,
          name: color,
          color: color,
        });
      }
      const donutHtml = createClusterDonut({
        size: 50,
        weight: 25,
        position: 'center',
        data: data,
        text: {
          text: cluster.getChildCount(),
          align: 'center',
        },
      });
      const htmlCluster =
        '<div class="clusterDonut">' + donutHtml.outerHTML + '</div>';
      return L.divIcon({ html: htmlCluster });
    },
  };
  return (L as any).markerClusterGroup(clusterOptions);
}


interface ICreateMarkers {
  pois: MapTypes.IPoi[]
  poisLinks: MapTypes.IPoiLink[]
  phaseTypes: MapTypes.IPhaseType[]
  poiTypes: MapTypes.IPoiType[]
  linkTypes: MapTypes.ILinkType[]
  onMarkerCreation?: (...args: any) => void
  category: MapTypes.IMapSelectionCategory
  searchedPoi?: MapTypes.IPoi
}

export const createMarkers = ({ searchedPoi, pois, poisLinks, phaseTypes, poiTypes, linkTypes, onMarkerCreation, category }: ICreateMarkers) => {
  const markersList: any = {};
  pois.forEach((p: MapTypes.IPoi) => {
    const icon: any = {
      className: `marker-icon ${searchedPoi?.id === p.id ? 'pois-selected' : ''}`,
      html: '',
    };
    const markerImage = getMarkerImage(
      p,
      poisLinks,
      phaseTypes,
      poiTypes,
      linkTypes,
      category,
      pois
    );
    if (markerImage) {
      icon.iconSize = new L.Point(markerImage.size[0], markerImage.size[1]);
      icon.iconAnchor = new L.Point(
        Math.round(markerImage.size[0] / 2),
        markerImage.size[1]
      );
      icon.tooltipAnchor = new L.Point(
        Math.round(markerImage.size[0] / 3),
        -Math.round(markerImage.size[0] / 2)
      );
      icon.html = ReactDOMServer.renderToString(markerImage.icon);
      icon.color = markerImage.color;
    }
    const poiType = poiTypes?.find(candidate => candidate.id === p.fkpoiType)
    const marker = L.marker(p.geo.coordinates, {
      icon: L.divIcon(icon),
    } as any).bindTooltip(`${poiType?.label !== undefined ? `${poiType.label} / ` : ''}${p.name}`);
    marker.on('mouseover', () => {
      marker.openTooltip();
    });
    marker.on('mouseout', () => {
      marker.closeTooltip();
    });
    onMarkerCreation?.(marker, p);
    (marker as any).Kid = p.id;
    (marker as any).PoiType = p.fkpoiType;
    (marker as any).PoiTypeColor = poiType?.style.data.markerColor
    markersList[p.id] = marker;
  });
  return markersList;
}

interface IMarkersLinkProps {
  originMarker: any;
  destMarker: any;
  map: any;
  clusters: any;
  options: any;
}

export class KLink extends L.Path {
  options = {
    fill: false,
    opacity: 1,
    weight: 2,
  };

  _circleMargin: number = 0;
  _clusters: any = {};
  _icon1: any;
  _icon2: any;
  _map: any;
  _marker1latlng: [number, number] = [0, 0];
  _marker2latlng: [number, number] = [0, 0];
  _options: any;
  _path: any = document.createElement('path');
  _point1: any;
  _point2: any;
  _r1: number = 0;
  _r2: number = 0;

  constructor({
    originMarker,
    destMarker,
    map,
    clusters,
    options,
  }: IMarkersLinkProps) {
    super(options);

    this._circleMargin = 0;
    this._clusters = clusters;
    this._icon1 = originMarker.options.icon;
    this._icon2 = destMarker.options.icon;
    this._map = map;
    // const position1 = this._getMarkerPosition(originMarker);
    // const position2 = this._getMarkerPosition(destMarker);
    this._options = options;

    var visibleMarker1 =
      originMarker.__parent &&
        originMarker.__parent._group.getVisibleParent(originMarker)
        ? originMarker.__parent._group.getVisibleParent(originMarker)
        : originMarker
          ? originMarker
          : null;
    var visibleMarker2 =
      destMarker.__parent &&
        destMarker.__parent._group.getVisibleParent(destMarker)
        ? destMarker.__parent._group.getVisibleParent(destMarker)
        : destMarker
          ? destMarker
          : null;

    if (visibleMarker1 && visibleMarker2) {
      this._marker1latlng = visibleMarker1._latlng;
      this._marker2latlng = visibleMarker2._latlng;

      this._r1 = visibleMarker1._icon
        ? visibleMarker1._icon.clientHeight + this._circleMargin
        : visibleMarker1.options.icon.options.iconSize?.y || 0;
      this._r2 = visibleMarker2._icon
        ? visibleMarker2._icon.clientHeight + this._circleMargin
        : visibleMarker2.options.icon.options.iconSize?.y || 0;
    } else {
      this._marker1latlng = [
        originMarker.getLatLng().lat,
        originMarker.getLatLng().lng,
      ];
      this._marker2latlng = [
        destMarker.getLatLng().lat,
        destMarker.getLatLng().lng,
      ];
      this._r1 = this._icon1?.options.iconSize.y + this._circleMargin;
      this._r2 = this._icon2?.options.iconSize.y + this._circleMargin;
    }

    this._point1 = this._map.latLngToLayerPoint(this._marker1latlng);
    this._point2 = this._map.latLngToLayerPoint(this._marker2latlng);
  }

  _getMarkerPosition = (marker: any) => {
    let cluster: any = undefined;
    this._clusters?.forEach((c: any) => {
      const typeKeys = Object.keys(c.ContainedPOIs);
      typeKeys?.forEach((k: string) => {
        if (c.ContainedPOIs[k].includes(marker.Kid)) {
          cluster = c;
        }
      });
    });
    return cluster?._latlng || marker.getLatLng();
  };

  redraw = () => {
    return this;
  };
  _project = () => {
    return this;
  };

  _update = () => {
    this._updatePath();
    this._updateStyle();
    return this;
  };

  _updatePath = () => {
    let str = this._getPathString();
    if (!str) {
      str = 'M0 0';
    }
    this._path.setAttribute('d', str);
  };

  _getPathString = () => {
    this._point1 = this._map.latLngToLayerPoint(this._marker1latlng);
    this._point2 = this._map.latLngToLayerPoint(this._marker2latlng);
    const p1 = this._point1;
    const p2 = this._point2;
    const r1 = this._r1 / 2;
    const r2 = this._r2 / 2;

    if (L.Browser.svg) {
      var p2pVector = {
        x: p1.x - p2.x,
        y: p1.y - p2.y,
      };
      var vectorLength = Math.sqrt(
        Math.pow(p2pVector.x, 2) + Math.pow(p2pVector.y, 2)
      );
      var correctionVector = {
        x: p2pVector.x / vectorLength,
        y: p2pVector.y / vectorLength,
      };
      if (isNaN(correctionVector.x)) correctionVector.x = 0;
      if (isNaN(correctionVector.y)) correctionVector.y = 0;
      var linkstr = '';

      var p1correctionX = r1 * correctionVector.x;
      var p1correctionY = r1 * correctionVector.y;

      var p2correctionX = r2 * correctionVector.x;
      var p2correctionY = r2 * correctionVector.y;

      // line string between markers
      linkstr +=
        'M' +
        (p1.x - p1correctionX) +
        ',' +
        (p1.y - r1 - p1correctionY) +
        'L' +
        (p2.x + p2correctionX) +
        ',' +
        (p2.y - r2 + p2correctionY);

      // Circle around the marker 1
      linkstr +=
        'M' +
        p1.x +
        ',' +
        (p1.y - r1 * 2) +
        'A' +
        r1 +
        ',' +
        r1 +
        ',0,1,1,' +
        (p1.x - 0.1) +
        ',' +
        (p1.y - r1 * 2);
      // circle around the marker 2
      linkstr +=
        'M' +
        p2.x +
        ',' +
        (p2.y - r2 * 2) +
        'A' +
        r2 +
        ',' +
        r2 +
        ',0,1,1,' +
        (p2.x - 0.1) +
        ',' +
        (p2.y - r2 * 2);

      return linkstr;
    }
  };

  _updateStyle = () => {
    // Color
    if (this._options.color) {
      this._path.setAttribute('stroke', this._options.color);
    } else {
      this._path.setAttribute('stroke', 'none');
    }
    // Opacity
    if (this._options.opacity) {
      this._path.setAttribute('stroke-opacity', this._options.opacity);
    } else {
      this._path.removeAttribute('stroke-opacity');
    }
    // Weight
    if (this._options.weight) {
      this._path.setAttribute('stroke-width', this._options.weight);
    } else {
      this._path.removeAttribute('stroke-width');
    }
    // dashArray
    if (this._options.dashArray) {
      this._path.setAttribute('stroke-dasharray', this._options.dashArray);
    } else {
      this._path.removeAttribute('stroke-dasharray');
    }
    // lineCap
    if (this._options.lineCap) {
      this._path.setAttribute('stroke-linecap', this._options.lineCap);
    } else {
      this._path.removeAttribute('stroke-linecap');
    }
    // lineJoin
    if (this._options.lineJoin) {
      this._path.setAttribute('stroke-linejoin', this._options.lineJoin);
    } else {
      this._path.removeAttribute('stroke-linejoin');
    }
    // fill color
    if (this._options.fill) {
      this._path.setAttribute(
        'fill',
        this._options.fillColor || this._options.color
      );
    } else {
      this._path.setAttribute('fill', 'none');
    }
    // fill opacity
    if (this._options.fillOpacity) {
      this._path.setAttribute('fill-opacity', this._options.fillOpacity);
    } else {
      this._path.removeAttribute('fill-opacity');
    }
  };
}

export const createLinks = ({
  links,
  linkTypes,
  markers,
  clusters,
  map,
  category
}: { map: any, markers: any, links: MapTypes.IPoiLink[], linkTypes: MapTypes.ILinkType[], clusters?: any, category: MapTypes.IMapSelectionCategory }) => {
  const linksList: any = {};
  links.forEach((l: MapTypes.IPoiLink & { toEdit?: boolean, toCreate?: boolean }) => {
    if (markers[l.fkpoiFrom] && markers[l.fkpoiTo] && (l.toEdit || l.toCreate || category.showMapLinks)) {
      const linkType = linkTypes.find(
        (t: MapTypes.ILinkType) => t.id === l.fklinkType
      );

      const key = `${l.fklinkType}---${l.fkpoiFrom}--${l.fkpoiTo}`;

      const markersLink = new KLink({
        originMarker: markers[l.fkpoiFrom],
        destMarker: markers[l.fkpoiTo],
        map,
        clusters,
        options: l.toEdit || l.toCreate || category.linkType.some(({ id }) => linkType?.id === id)
          ? linkType?.style.data ?? { color: '#dddddd' }
          // PoiTypeColor is injected on the marker creation
          : { color: markers[l.fkpoiTo].PoiTypeColor },
      });
      linksList[key] = markersLink;
    }
  });
  return linksList;
}